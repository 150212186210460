<template>
  <div>
    <a-upload
      :fileList="fileList"
      listType="picture-card"
      :multiple="false"
      :customRequest="customRequest"
      @change="onChange"
      @preview="handlePreview"
    >
      <div v-if="fileList.length === 0">
        <a-icon type="plus" />
        <div>上传</div>
      </div>
    </a-upload>

    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import { logoImageUpload } from "@/api/system";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
export default {
  props: ["initialItem"],
  model: { prop: "value", event: "change" },
  data() {
    return {
      fileList: [],
      previewVisible: false,
      previewImage: null,
    };
  },
  methods: {
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    customRequest({ file, onError, onSuccess }) {
      const dataForm = new FormData();
      dataForm.append("file", file);

      logoImageUpload(dataForm)
        .then((data) => onSuccess(data))
        .catch(() => onError({ name: "错误", message: "上传错误" }));
    },
    onChange({ fileList }) {
      this.fileList = fileList;
      const fileSet = [];
      for (const fileItem of fileList) {
        if (fileItem.status === "done") {
          fileSet.push(fileItem.response.id);
        }
      }

      this.$emit("change", fileSet[0] ?? null);
    },
  },
  mounted() {
    if (this.initialItem) {
      this.fileList = [
        {
          uid: `-${Date.now() * Math.random()}`,
          name: this.initialItem.name,
          status: "done",
          url: this.initialItem.file,
          response: this.initialItem,
        },
      ];
    }
  },
};
</script>
